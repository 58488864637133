// i18next-extract-mark-ns-start overview-page
import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import Cover from '../../components/Cover/Cover';
import { Seo } from '../../components/Seo';
import Layout from '../../layouts/layout';
import * as classes from './integrations.module.scss';

const IntegrationsPage = props => {
    return <Layout location={props.location}>
        <Cover
            title={<Trans i18nKey="integrations-page:pageTitle">Integrations</Trans>}
            subtitle={<Trans i18nKey="integrations-page:pageSubtitle">Unleash the Efficiency of Intelligent Document
                                                                      Connections</Trans>}
            cover="integrations.jpg"
        />
        <div className="container p-0 intro">
            <Trans i18nKey="integrations-page:pageDescription">
                <p>AIDA's intelligent document processing platform is designed to effortlessly connect with the tools
                   you use <strong>every day</strong>.
                   With out-of-the-box integration with Google Drive, Dropbox, SAP, Microsoft (SharePoint / OneDrive),
                   Xerox ECM products, and the Apple ecosystem, AIDA ensures a seamless workflow that enhances
                   productivity and efficiency.</p>
                <p>Our <strong>no-code approach</strong> makes integration a breeze, allowing you to focus on what
                   matters most -
                   managing your documents.</p>
                <p>AIDA's integrations are more than just connections; they are bridges to a more streamlined and
                   intelligent way of handling your documents.
                   Whether it's archiving, task management, or extracting valuable insights, AIDA's integrations with
                    <strong>leading platforms</strong> enable you to do more with less effort.
                   Experience the future of document processing with AIDA's robust integrations.
                </p>
            </Trans>
        </div>
        <div className={clsx(classes.General)}>
            <div className={clsx(classes.Integrations, 'container p-0')}>
                <h1><Trans>Integrations</Trans></h1>
                <div className="subtitle" style={{ marginBottom: '50px' }}><
                    Trans>AIDA connects with your world</Trans>
                </div>
                <div className={classes.Boxes}>
                    <div className={classes.Box}>
                        <StaticImage
                            height={55}
                            src="../../images/integrations/tclab-aida-integration-aida-link.png"
                            placeholder="none"
                            alt="AIDA Link - Connect on-premise resources" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            height={55}
                            src="../../images/integrations/tclab-aida-integration-aida-team.png"
                            placeholder="none"
                            alt="AIDA Team - Collaborate on documents" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            height={55}
                            src="../../images/integrations/tclab-aida-integration-aida-webhook.png"
                            placeholder="none"
                            alt="AIDA Webhook - Rest API client" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={98}
                            height={120}
                            src="../../images/integrations/tclab-aida-integration-apple.jpg"
                            alt="Apple" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={202}
                            height={40}
                            src="../../images/integrations/tclab-aida-integration-dropbox.png"
                            placeholder="none"
                            alt="Dropbox" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={247}
                            height={40}
                            src="../../images/integrations/tclab-aida-integration-microsoft-onedrive.png"
                            placeholder="none"
                            alt="Microsoft OneDrive" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={199}
                            height={40}
                            src="../../images/integrations/tclab-aida-integration-microsoft-sharepoint.png"
                            placeholder="none"
                            alt="Microsoft SharePoint" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={137}
                            height={120}
                            src="../../images/integrations/tclab-aida-integration-google-drive.png"
                            placeholder="none"
                            alt="Google Drive" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={74}
                            height={120}
                            src="../../images/integrations/tclab-aida-integration-email.png"
                            placeholder="none"
                            alt="Email" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={120}
                            height={120}
                            src="../../images/integrations/tclab-aida-integration-sap.png"
                            placeholder="none"
                            alt="Sap" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={217}
                            height={40}
                            src="../../images/integrations/tclab-aida-integration-teamsystem.png"
                            placeholder="none"
                            alt="TeamSystem"
                        />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={173}
                            height={40}
                            src="../../images/integrations/tclab-aida-integration-dolibarr.png"
                            placeholder="none"
                            alt="Dolibarr ERP/CRM" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={157}
                            height={91}
                            src="../../images/integrations/tclab-aida-integration-xerox-docushare.png"
                            placeholder="none"
                            alt="Xerox DocuShare"
                        />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={157}
                            height={87}
                            src="../../images/integrations/tclab-aida-integration-xerox-docushare-flex.png"
                            placeholder="none"
                            alt="Xerox DocuShare Flex"
                        />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={157}
                            height={91}
                            src="../../images/integrations/tclab-aida-integration-xerox-docushare-go.jpg"
                            alt="Xerox DocuShare Flex"
                        />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={160}
                            height={50}
                            src="../../images/integrations/tclab-aida-integration-xerox-mfp.png"
                            placeholder="none"
                            alt="Xerox devices" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={174}
                            height={40}
                            src="../../images/integrations/tclab-aida-integration-whatsapp.jpg"
                            alt="WhatsApp" />
                    </div>
                    <div className={classes.Box}>
                        <StaticImage
                            width={115}
                            height={120}
                            src="../../images/integrations/tclab-aida-integration-ftp.jpg"
                            alt="WhatsApp" />
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};

export default IntegrationsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "integrations-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'integrations-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Integrations';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};
